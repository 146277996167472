.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
}

/* Roboto Font */
body, html {
  font-family: "Roboto", sans-serif;
  min-height: 100% !important;
  height: 100%;
}

/*Container class*/
.container {
  max-width: 900px;
  margin: 0 auto;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-flex.flex-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/*Title and Subtitle */
.title {
  font-size: 3.75rem;
  font-weight: 500;
  margin: 3rem 0;
  color: white;
}

.text-light {
  color: #f8f9fa;
}

/*Main Btn Class*/
.btn {
  margin: 0.25rem 0.125rem;
  padding: 0.35rem 1.25rem;
  font-family: inherit;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.6px;
  border-radius: 0.3rem;
  color: #fff;
  background-color: #000;
  border: none;
  box-shadow: 0 2px 5px rgba(51, 51, 51, 0.3);
  transition: 0.3s ease;

  align-items: center;
}
.btn:hover,
.btn-accept:hover,
.btn-warning:hover {
  cursor: pointer;
  box-shadow: none;
  transition: 0.3s ease;
  background: rgba(0, 0, 0, 0.75);
}

/* Btn Types*/

.btn-danger {
  background-color: #dc3545;
  color: white;
}
.btn-danger:hover {
  background: rgba(220, 53, 69, 0.75);
}

.btn-warning {
  background-color: #ffc107;
  color: white;
  color: #000;
}
.btn-warning:hover {
  background: rgba(255, 193, 7, 0.75);
}

.btn-accept {
  background-color: #284ea7;
  color: white;
}
.btn-accept:hover {
  background: rgba(40, 78, 167, 0.75);
}

.btn-lg {
  margin: 0.3125rem 0.35625rem;
  padding: 0.7375rem 3.5625rem;
  font-size: 20px;
}

.wrapper {
  z-index: 9;
  width: 100%;
  height: 100%;
  text-align: center;
  flex-grow: 1;
  position: relative;
}

.timers {
  z-index: 1;
  display: flex;
  width: 100%;
  overflow: auto;
  height: 100vh;
  position: absolute;
}

.whiteSide {
  background: rgb(239, 239, 239);
  float:left;
  width: 50%;
}

.blackSide {
  background: #282C34;
  float: right;
  width: 50%;
}

.controls {
  z-index: 8;
  bottom: 15%;
  left: 40%;
  display: inline-block;
  width: 20%;
  position: fixed;
}

.buttons {
  display: block;
  background: #222;
  height: 100px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 5px 4px 6px;
}

.version {
  position: fixed;
  z-index: 8;
  left: 5px;
  bottom: 5px;
}

.license {
  padding-top: 50px;
  height: 100%;
  width: 100%;
  color: #222222;
  text-align: center;
  font-size: 33px;
  line-height: 1.6;
}

.license-text {
  width: 35%;
  color: #222222;
  text-align: center;
  font-size: 33px;
  line-height: 1.6;
  margin: auto;
}

.link {
  font-weight: bold;
}